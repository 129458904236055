import React, { PureComponent } from 'react'
import { addMessage } from 'src/actions/ToastActions'
import { toggleModal, updateModalLayerContent } from 'cargo'
import { connect } from 'react-redux'
import { Documentation, Card } from 'cargo'
import MappingGrid from './MappingGrid';
import Error from './Error';
import Loader from './Loader';
import TrackingSettings from 'src/components/TrackingSettings'
import CustomizationSettings from 'src/components/CustomizationSettings'
import CheckboxSettings from 'src/components/CheckboxSettings'
import TemplateSettings from "./TemplateSettings"

class MappingSettings extends PureComponent {
  state = {
    mappings: [],
    mappingStatuses: {},
    mappingError: false,
    loading: true,
    saving: false
  }

  async componentDidMount() {
    await this.fetchData()
  }

  fetchData = async () => {
    const mappingsResponse = await this.props.getMappings()
    const mappings = await mappingsResponse.json()

    const mappingStatuses = await this.props.getMappingStatuses(mappings)

    this.setState({
      mappings: mappingsResponse.status === 200 ? mappings : null,
      mappingError: mappingsResponse.status !== 200,
      mappingStatuses,
      loading: false
    })
  }

  generateModalContent = id => {
    const { idField, quickbooks, generateSections } = this.props
    const mapping = this.state.mappings.find(mapping => mapping[idField] === id)
    const mappingStatuses = id ? this.state.mappingStatuses[id] : []
    const { accountMapping, tracking, customization, templates } = generateSections(quickbooks, mapping)
    return (
      <div style={{ width: '100%' }}>
        <MappingGrid
          fields={accountMapping}
          mappingRef={mapping}
          handleMappingChange={this.handleMappingChange(id)}
        />
        <TrackingSettings
          rows={tracking}
          mappingStatuses={mappingStatuses}
          fields={accountMapping}
          handleMappingChange={this.handleMappingChange(id)}
          handleSettingChange={this.handleSettingChange(id)}
          mappingRef={mapping}
          isDefaultMapping={id === null}
        />
        <CustomizationSettings
          rows={customization}
          handleSettingChange={this.handleSettingChange(id)}
          mappingRef={mapping}
        />
        <CheckboxSettings
          sectionTitle={'Sales Channel Fee Type Settings'}
          rows={this.getFeeTypeRowsForChannelType(mapping)}
          handleSettingChange={this.handleFeeSettingChange(id)}
          sectionKeyHeader={'Sales Channel Fee Type'}
          sectionOptionHeader={'Sync Enabled'}
          mappingRef={mapping}
        />
        <TemplateSettings
          rows={ templates }
          handleTemplateDataChange={ this.handleTemplateDataChange(id) }
          mappingRef={ mapping }
        />
      </div>
    )
  }

  getFeeTypeRowsForChannelType = (mapping) => {
    var rows = []

    if (mapping.salesChannelFeeTypeSettings == undefined ||
      mapping.salesChannelFeeCreatedTrackSetting == undefined ||
      !mapping.salesChannelFeeCreatedTrackSetting.enabled) {
      return rows;
    }

    if (mapping.defaultMapping) {
      rows = [{
        displayText: 'Sales Channel Fees',
        key: 'SALES_CHANNEL_FEE',
        enabled: true,
        documentationId: '7bc3UWPSyczs8UMswmDWl8'
      },
        {
          displayText: 'Order Fees',
          key: 'ORDER_FEE',
          enabled: false,
          documentationId: '6Qq7NlA8Zx1GBobEUMpXM6'
        },
        {
          displayText: 'Order Item Fees',
          key: 'ORDER_ITEM_FEE',
          enabled: false,
          documentationId: 'fCJ4OocrHUzK3OXhzgmis'
        },
        {
          displayText: 'Order Item Promotion Fees',
          key: 'ORDER_ITEM_PROMO',
          enabled: false,
          documentationId: 'ZUAWmhWTkG1yub92esogi'
        },
        {
          displayText: 'Order Item Finance Event Fees',
          key: 'ORDER_ITEM_FINANCE_EVENT_FEE',
          enabled: false,
          documentationId: '3aeIji8JPt0wTy4dkDw6XO'
        },
        {
          displayText: 'Order Item Damaged Fees',
          key: 'ORDER_ITEM_DAMAGE_FEE',
          enabled: false,
          documentationId: 'lUJW90tf4esGwoqYTka3q'
        },
        {
          displayText: 'Order Item Commission Fees',
          key: 'ORDER_ITEM_COMMISSION',
          enabled: false,
          documentationId: '49Wx6Tt7NvlFuwHC1la8AC'
        },
        {
          displayText: 'Order Item Commission Adjustment Fees',
          key: 'ORDER_ITEM_COMMISSION_ADJUSTMENT',
          enabled: false,
          documentationId: '7iroT1fkLMeGBX3TMqVQi'
        }]
    }

    switch (mapping.salesChannelType) {
      case 'AMAZON':
      case 'AMAZON_CN':
      case 'AMAZON_AU':
      case 'AMAZON_DE':
      case 'AMAZON_FR':
      case 'AMAZON_ES':
      case 'AMAZON_IT':
      case 'AMAZON_MX':
      case 'AMAZON_UK':
      case 'AMAZON_CA':
        rows = [{
          displayText: 'Sales Channel Fees',
          key: 'SALES_CHANNEL_FEE',
          enabled: true,
          documentationId: '7bc3UWPSyczs8UMswmDWl8'
        },
          {
            displayText: 'Order Fees',
            key: 'ORDER_FEE',
            enabled: false,
            documentationId: '6Qq7NlA8Zx1GBobEUMpXM6'
          },
          {
            displayText: 'Order Item Fees',
            key: 'ORDER_ITEM_FEE',
            enabled: false,
            documentationId: 'fCJ4OocrHUzK3OXhzgmis'
          },
          {
            displayText: 'Order Item Promotion Fees',
            key: 'ORDER_ITEM_PROMO',
            enabled: false,
            documentationId: 'ZUAWmhWTkG1yub92esogi'
          },
          {
            displayText: 'Order Item Finance Event Fees',
            key: 'ORDER_ITEM_FINANCE_EVENT_FEE',
            enabled: false,
            documentationId: '3aeIji8JPt0wTy4dkDw6XO'
          },
          {
            displayText: 'Order Item Damaged Fees',
            key: 'ORDER_ITEM_DAMAGE_FEE',
            enabled: false,
            documentationId: 'lUJW90tf4esGwoqYTka3q'
          }]
        break;
      case 'WALMART':
        rows = [{
          displayText: 'Order Item Commission Fees',
          key: 'ORDER_ITEM_COMMISSION',
          enabled: false,
          documentationId: '49Wx6Tt7NvlFuwHC1la8AC'
        },
          {
            displayText: 'Order Item Commission Adjustment Fees',
            key: 'ORDER_ITEM_COMMISSION_ADJUSTMENT',
            enabled: false,
            documentationId: '7iroT1fkLMeGBX3TMqVQi'
          }]
        break;
      case 'EBAY':
        rows = [{
          displayText: 'Order Item Fees',
          key: 'ORDER_ITEM_FEE',
          enabled: false,
          documentationId: 'fCJ4OocrHUzK3OXhzgmis'
        }]
        break;
    }

    rows.forEach(row => {
      var feeTypeSetting = mapping.salesChannelFeeTypeSettings.find(feeTypeSetting => feeTypeSetting.salesChannelFeeType == row.key);
      if (feeTypeSetting != undefined) {
        row.enabled = feeTypeSetting.enabled
      }
    });

    return rows;
  }

  updateMapping = (id, translationFunc) => {
    const { idField } = this.props
    const { mappings } = this.state
        let idx = mappings.findIndex(mapping => mapping[idField] === id)

    mappings[idx] = translationFunc(mappings[idx])

    this.setState({ mappings: [...mappings] }, () => {
      this.props.updateModalLayerContent({ body: this.generateModalContent(id) })
    })
  }

  handleMappingChange = id => fieldKey => ({ target: { value } }) => {
    if (value === 'Use Default') value = null
    this.updateMapping(id, mapping => ({ ...mapping, [fieldKey]: value }))
  }

  handleSettingChange = id => (settingKey, settingField) => ({ target: { value } }) => {
    if (value === 'Use Default') value = null
    this.updateMapping(id, mapping => ({
      ...mapping,
      [settingKey]: { ...mapping[settingKey], [settingField]: value }
    }))
  }

  handleFeeSettingChange = id => (settingKey, settingField) => ({ target: { value } }) => {
    const { idField } = this.props
    const { mappings } = this.state
    let idx = mappings.findIndex(mapping => mapping[idField] === id)

    var existingFeeSetting = mappings[idx].salesChannelFeeTypeSettings.find(feeTypeSetting => feeTypeSetting.salesChannelFeeType == settingKey);
    if(existingFeeSetting == undefined) {
      mappings[idx].salesChannelFeeTypeSettings.push({salesChannelFeeType: settingKey, enabled : value});
    } else {
      mappings[idx].salesChannelFeeTypeSettings.find(feeTypeSetting => feeTypeSetting.salesChannelFeeType == settingKey).enabled = value;
    }
    this.setState({ mappings: [...mappings] }, () => {
      this.props.updateModalLayerContent({ body: this.generateModalContent(id) })
    })
  }

  handleTemplateDataChange = id => (typeOfChange, event, value, lineId) => {
    const { idField } = this.props
    const { mappings } = this.state
    let idx = mappings.findIndex(mapping => mapping[idField] === id)

    if (typeOfChange == 'default') {
      this.updateOrCreateDefaultValueForTemplate(mappings, idx, event, value, lineId);
    } else {
      this.updateOrCreateTemplateValue(mappings, idx, event, value, lineId);
    }

    this.setState({ mappings: [...mappings] }, () => {
      this.props.updateModalLayerContent({ body: this.generateModalContent(id) })
    })
  }

  updateOrCreateDefaultValueForTemplate(mappings, idx, event, value, lineId) {
    if (mappings[idx][event] == undefined || mappings[idx][event].length <= 0) {
      mappings[idx][event] = [{ 'useDefault': value, 'lineType': lineId }]
    } else if (mappings[idx][event].filter(templatingValue => templatingValue.lineType === lineId).length > 0) {
      mappings[idx][event].filter(templatingValue => templatingValue.lineType === lineId)[0]['useDefault'] = value;
    } else {
      mappings[idx][event].push({ 'useDefault': value, 'lineType': lineId });
    }
  }

  updateOrCreateTemplateValue(mappings, idx, event, value, lineId) {
    if (mappings[idx][event] == undefined || mappings[idx][event].length <= 0) {
      mappings[idx][event] = [{ 'templateValue': value, 'lineType': lineId }]
    } else if (mappings[idx][event].filter(templatingValue => templatingValue.lineType === lineId).length > 0) {
      mappings[idx][event].filter(templatingValue => templatingValue.lineType === lineId)[0]['templateValue'] = value;
    } else {
      mappings[idx][event].push({ 'templateValue': value, 'lineType': lineId });
    }
  }

  showModal = id => {
    const { toggleModal, idField, title } = this.props
    const { name } = this.state.mappings.find(mapping => mapping[idField] === id)

    // Convert to singular
    const normalizedTitle = title[title.length - 1] === 's' ? title.substring(0, title.length - 1) : title

    toggleModal({
      title: `Configure "${name}" ${normalizedTitle}`,
      body: this.generateModalContent(id),
      confirmText: 'Save',
      cancelText: 'Cancel',
      onConfirm: this.submitForm(id),
      onCancel: toggleModal
    })

    document.getElementsByTagName('body')[0].classList.add('scroll-disabled')
  }

  submitForm = id => async () => {
    const { toggleModal, addMessage, idField, title } = this.props
    toggleModal()

    this.setState({ saving: true })
    const mapping = this.state.mappings.find(mapping => mapping[idField] === id)

    let submitResponse = await this.props.submitMapping(mapping)

    await this.fetchData()

    this.setState({ saving: false })

    addMessage({
      type: submitResponse.status === 201 ? 'Success' : 'Failure',
      message: submitResponse.status === 201 ? `Your ${title} settings have been saved` : `An error occurred, your ${title} settings have not been saved`
    })
  }

  findAccountName = accountId => {
    if (accountId === null) return 'N/A'

    const account = this.props.quickbooks.quickbooksAccounts.find(({ id }) => id === accountId)
    if (account) return account.name

    return accountId
  }

  render() {
    const { saving, loading, mappingError, mappings } = this.state
    const {
      title,
      idField,
      headerRow,
      displayRows,
      documentationId,
      quickbooks: { error }
    } = this.props

    if (mappingError || error) {
      const { status, message } = error || mappingError
      return <Error status={status} message={message}/>
    }

    if (loading || saving) return <Loader/>

    return (
      <div style={{ marginTop: '20px' }}>
        <Card>
          <div>
            <h3>{title}</h3>
            <Documentation entryId={documentationId}/>
          </div>
          <div className='Row HeaderRow'>
            {headerRow.map(col => <div className='RowItem' key={col}><p>{col}</p></div>)}
          </div>
          {mappings.map(mapping => (
            <div className='Row' key={mapping.name}>
              <div className='RowItem'>
                <p>{mapping.name}</p>
              </div>
              {displayRows.map(key => (
                <div className='RowItem' key={key}>
                  <p>{this.findAccountName(mapping[key])}</p>
                </div>
              ))}
              <div className='RowItem'>
                <button
                  className='EditButton'
                  onClick={() => this.showModal(mapping[idField])}
                >
                  Edit Mappings
                </button>
              </div>
            </div>
          ))}
        </Card>
      </div>
    )
  }
}

export default connect(null, { addMessage, toggleModal, updateModalLayerContent })(MappingSettings)
