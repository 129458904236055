import React, { Component } from 'react'
import {Button} from 'cargo'

import './DocumentedLabel.scss'

export default class TemplateInput extends Component {

    constructor(props) {
        super(props)
        this.state = {
            input: props.inputValue,
            cursorIndex: 0,
            useDefault: props.useDefault
        }

        this.templateInput = React.createRef();
    }

    addValueToInput = (event, templateInputValue, idField, lineValue) => {
        event.preventDefault()

        let newInputValue
        if (this.state.input) {
            newInputValue = [this.state.input.slice(0, this.state.cursorIndex), templateInputValue, this.state.input.slice(this.state.cursorIndex)].join('')
        } else {
            newInputValue = templateInputValue
        }

        this.setState({
            input: newInputValue,
            cursorIndex: this.state.cursorIndex + templateInputValue.length
        })

        this.props.handleTemplateDataChange("template", idField, newInputValue, lineValue);

        return false;
    }

    handleTemplateChange = (idField, lineValue) => event => {
        this.setState({input: event.target.value, cursorIndex: event.target.selectionStart})
        this.props.handleTemplateDataChange("template", idField, event.target.value, lineValue);
    }

    handleDefaultChange = (idField, lineValue) => event => {
        var useDefaultUpdate = !this.state.useDefault
        this.setState({...this.state.input, ...this.state.cursorIndex, useDefault: useDefaultUpdate})
        this.props.handleTemplateDataChange("default", idField, useDefaultUpdate, lineValue);
    }

    updateCursorIndex = event => {
        this.setState({ cursorIndex: event.target.selectionStart })
    }


    render() {
        const {inputValue, templatingValues, event, handleTemplateDataChange, idField, lineValue} = this.props
        const hidden = this.state.useDefault ? 'hidden' : 'form-group mb-0 text-center';
        return (
            <div style={{width: '100%', textAlign: 'right'}}>

                <p style={{width: '100%', 'text-align': 'center'}}>{lineValue.name}</p>
                <label>
                    <input type="checkbox" checked={this.state.useDefault}
                           onChange={this.handleDefaultChange(idField, lineValue.id)}/>
                    &nbsp;Use Default Template
                </label>
                <div className={hidden}>
                    <div>
                        <input onClick={this.updateCursorIndex} className="form-control" style={{width: '100%'}}
                               type='text'
                               value={this.state.input}
                               onChange={this.handleTemplateChange(idField, lineValue.id)}
                               ref={this.templateInput}/>
                    </div>

                    {templatingValues.map((templatingValue) => {
                        return (
                            <Button
                                onClick={(event) => this.addValueToInput(event, templatingValue.templateInputValue, idField, lineValue.id)}
                                type="button" style={{margin: '10px', width: '150px'}}
                                text={templatingValue.templateDisplayValue}
                            />
                        )
                    })}
                </div>
            </div>
        )
    }
}
