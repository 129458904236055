import React from 'react'
import errorImage from 'hyper/public/images/startman.svg'
import logo from 'src/images/gotham_works_logo.svg'
import './Error.scss'

export default ({ status, message }) => (
  <div className='error-card container d-inline-flex justify-content-center mt-4'>
    <div className='col-lg-8'>
      <div className='card'>
        <div className='card-header pt-2 pb-2 text-center bg-primary'>
          <a href='/' className='logo logo-lg text-center' dangerouslySetInnerHTML={{ __html: logo }} />
        </div>
        <div className='card-body p-4'>
          <div className='text-center'>
            <div className='error-image' dangerouslySetInnerHTML={{ __html: errorImage }} />
            { status && <h1 className='text-error mt-4'>{ status }</h1> }
            { message && <h4 className='text-uppercase text-danger mt-3'>{ message }</h4> }
            <p className='text-muted mt-3'>
              Why not try refreshing your page? or you can contact
              <a
                href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
                className='text-muted'>
                  <b>Support</b>
              </a>
            </p>
            <a className='btn btn-info mt-3' href='/'>
              <i className='mdi mdi-reply'/> Return Home
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
)
