import React, { PureComponent } from 'react'

import './Settings.scss'
import DocumentedLabel from './DocumentedLabel'
import TemplateInput from "./TemplateInput";

export default class TemplateSettings extends PureComponent {


  render() {
    const { rows, mappingRef, handleTemplateDataChange } = this.props
    if (rows == null || (Array.isArray(rows) && rows.length < 1)) {
      return ''
    }
    return (
      <div className='Section'>
        <div className='SectionHeader'>
          <p className='SectionTitle'>Line Description Template Settings</p>
        </div>
        <div className='SettingRowContainer'>
          <div className='SettingHeader'>
            <div><p>Flow</p></div>
            <div style={{ justifyContent: 'center', textAlign: 'center', width: '100%' }}><p>Line Description
              Template</p>
            </div>
          </div>
          {rows.map(({ title, documentationId, idField, templatingValues = [], lines }) => {

            return (
              <div className='SettingRow' key={title} style={{ display: 'flex', alignItems: 'start' }}>
                <div>
                  <DocumentedLabel documentationId={documentationId}>
                    {title}
                  </DocumentedLabel>
                </div>
                <div style={{ width: '100%' }}>
                  <>
                    {lines.map((lineVal) => {
                      const templateFieldValue = mappingRef[idField] != undefined && mappingRef[idField].filter(val => val.lineType === lineVal.id).length > 0 ?
                        mappingRef[idField].filter(val => val.lineType === lineVal.id)[0]['templateValue']
                        : '';

                      const useDefaultValue = mappingRef[idField] != undefined && mappingRef[idField].filter(val => val.lineType === lineVal.id).length > 0 ?
                        mappingRef[idField].filter(val => val.lineType === lineVal.id)[0]['useDefault']
                        : true;

                      return (
                        <TemplateInput templatingValues={templatingValues}
                                       lineValue={lineVal}
                                       inputValue={templateFieldValue}
                                       idField={idField}
                                       useDefault={useDefaultValue}
                                       handleTemplateDataChange={handleTemplateDataChange}></TemplateInput>)
                    })}
                  </>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}
