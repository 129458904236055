import React, { Component } from 'react'
import './MappingTable.css'
import DocumentedLabel from '../DocumentedLabel';
import TemplateInput from "../TemplateInput";

const DEFAULT_VALUE = 'Select...'

export default class MappingTable extends Component {
  state = { formData: [] }

  componentDidMount() {
    this.calculateRows(this.props.rows)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.rows !== this.props.rows) this.calculateRows(this.props.rows)
  }

  calculateRows = rows => {
    const formData = [...rows].sort((a, b) => a[0].name > b[0].name ? 1 : -1).map(row => {

      const selectedIds = []
      const id = row[0].idField
      for (let col = 1; col < row.length; col++) {
        if(row[col].hasOwnProperty('templatingValues')) {
          return {id,name: row[col].name, useDefault: row[col].useDefault};
        } else {
          const {selectedId} = row[col];
          selectedIds.push(selectedId);
        }
      }
      return { id, selectedIds }
    })

    this.setState({ formData })
  }

  handleChange = async ({ target }) => {
    const { value } = target
    const rowidx = target.getAttribute('rowidx')
    const colidx = target.getAttribute('colidx')

    const { formData } = this.state
    formData[rowidx].selectedIds[colidx] = value

    if (value !== DEFAULT_VALUE) this.setState({ formData })
  }

  handleSubmit = event => {
    event.preventDefault()
    if (this.props.enabled) {
      this.props.submitForm(this.state.formData)
    }
  }

  handleTemplateDataChange = (field, fieldId, value, lineId) => {
    const {formData} = this.state

    formData.forEach((e) => {
      if (e.id == fieldId) {
        if (field == "template") {
          e.name = value
        } else {
          e.useDefault = value
        }
      }
    });
    this.setState({formData})
  }

  render() {
    const { headers, saving, enabled, rows } = this.props

    const headerRow = headers.map((header, idx) => <th key={`heading-${idx}`}>{ header }</th>)

    const otherRows = [...rows].sort((a, b) => a[0].name > b[0].name ? 1 : -1).map((row, idx) => {
      const content = row.map((cellValue, idx2) => {
        let content
        let className

        if (cellValue.hasOwnProperty('options')) {
          content = this[enabled ? 'makeSelect' : 'valueOnly'](cellValue.options, idx, idx2 - 1, cellValue.disabled)
          className = 'right-align'
        } else if(cellValue.hasOwnProperty('templatingValues')) {
          content = (
            <TemplateInput templatingValues={cellValue.templatingValues}
                           lineValue={cellValue.lineValue}
                           inputValue={cellValue.name}
                           idField={cellValue.idField}
                           useDefault={cellValue.useDefault}
                           handleTemplateDataChange={this.handleTemplateDataChange}
            />
          )

        } else {
          content = (
            <>
              <DocumentedLabel documentationId={ cellValue.documentationId }>{ cellValue.name }</DocumentedLabel>
              <input
                type='hidden'
                value={ cellValue.id }
              />
            </>
          );

          className = 'left-align'
        }

        return (
          <td cy-key={ idx2 } key={ `${idx}-${idx2}` } className={ className }>
            { content }
          </td>
        );
      });

      return <tr key={`row-${idx}`}>{ content }</tr>
    })

    return (
      <form onSubmit={ this.handleSubmit } className='mapping-form'>
        <div className='table-responsive-sm'>
          <table className='table table-centered mb-0'>
            <thead>
              <tr>{ headerRow }</tr>
            </thead>
            <tbody>{ otherRows }</tbody>
          </table>
        </div>
        <div className='col-sm-3 offset-sm-9'>
          {enabled && (
            <button
              className='btn-primary btn-md btn-block btn waves-effect waves-light'
              disabled={ saving }
              type='submit'
              id='todo-btn-submit'
            >
              {saving ? (<span><i className='fa fa-circle-o-notch fa-spin'/> Saving </span>) : 'Save'}
            </button>
          )}
        </div>
      </form>
    )
  }

  valueOnly = (options, rowidx, colidx) => {
    const selectedId = this.state.formData?.[rowidx]?.selectedIds?.[colidx]
    const matches = options.filter(({ id }) => id === selectedId)

    return <span>{ matches.length > 0 ? matches[0].name : '' }</span>
  }

  makeSelect = (options, rowidx, colidx, disabled) => {
    const selectedId = this.state.formData?.[rowidx]?.selectedIds?.[colidx]
    const htmlOptions = [!selectedId && (<option key='select-0'>{DEFAULT_VALUE}</option>)].filter(Boolean)

    const sortedOptions = options.natural ? options : [...options].sort((a, b) => {
      if (a.acctNum > b.acctNum) return 1
      if (a.acctNum < b.acctNum) return -1
      return a.name > b.name ? 1 : -1
    })

    sortedOptions.forEach((option, index) => {
      htmlOptions.push(
        <option
          key={ index }
          value={ option.id }
          selected={ option.id === selectedId ? 'selected' : undefined }
        >
          { option.acctNum ? `${option.acctNum} ` : null }
          { option.name }
        </option>
      );
    });

    return (
      <select
        disabled={ disabled }
        className='form-control'
        title={ disabled ? 'Sorry, you cannot update this mapping right now. Reason: We\'re still updating your products in QBO for a previous update.' : ''}
        onChange={ this.handleChange }
        rowidx={ rowidx }
        colidx={ colidx }
      >
        { htmlOptions }
      </select>
    );
  }
};
