import React from 'react'
import {connect} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {Card, Documentation} from 'cargo'
import {
  faArrowRight,
  faCalendarPlus,
  faCheckCircle,
  faLongArrowAltDown,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons'
import Utils from 'src/utils/Utils'
import Login from 'src/pages/Login/Login'
import c2qbImg from 'src/images/C2QB_white_btn_lg_default.png'
import logo from 'src/images/gotham_works_logo.svg'
import './Account.css'

library.add(
  faCheckCircle,
  faTimesCircle,
  faArrowRight,
  faLongArrowAltDown,
  faCalendarPlus,
)

const SetupStep = ({ children }) => (
  <div className='row' style={{
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: '2em',
    marginBottom: '2em'
  }}>
    { children }
  </div>
)

class Account extends React.PureComponent {
  quickbooksInstalled = (skubannaLoggedIn, skubanaAuthorized) => (
    <SetupStep>
      {skubanaAuthorized ? (
        <div className='row' style={{ justifyContent: 'center' }}>
            <span className='col-sm-3 align-middle'>
              <FontAwesomeIcon icon='check-circle' style={{ fontSize: '3.5em', color: 'green' }} />
            </span>
          <div className='col-sm-9'>
            <h3 style={{ lineHeight: '2', textTransform: 'uppercase' }}>Installed</h3>
          </div>
          <FontAwesomeIcon icon='long-arrow-alt-down' style={{ fontSize: '3em', color: '#cccccc' }} />
        </div>
      ) : (
        <div>
          <h4>To get started, install our app from the Skubana App Store.</h4>
          <a href={`${Utils.getAppStoreUrl()}`} className='btn btn-primary btn-lg'>
            Install&nbsp;
            <FontAwesomeIcon icon='arrow-right' style={{ 'fontSize': '0.8em' }} />
          </a>
        </div>
      )}
    </SetupStep>
  )

  quickbooksConnected = (skubanaAuthorized, quickbooksAuthorized, quickbooksOauthUrl) => (
    <SetupStep>
      {quickbooksAuthorized ? (
        <div className='row' style={{ justifyContent: 'center' }}>
            <span className='col-sm-3 align-middle'>
              <FontAwesomeIcon icon='check-circle' style={{ fontSize: '3.5em', color: 'green' }}/>
            </span>
          <div className='col-sm-9'>
            <h3 style={{ lineHeight: '2', textTransform: 'uppercase' }}>Connected</h3>
          </div>
        </div>
      ) : (
        <div>
          <h4 style={{ marginBottom: '1em' }}>Next, connect the app to QuickBooks Online.</h4>
          <a className='qbImgLink' href={ quickbooksOauthUrl }>
            <img style={{ height: '40px' }} src={ c2qbImg } alt='QuickBooks Connect Button' />
          </a>
        </div>
      )}
    </SetupStep>
  )

  quickbooksConnectedAndInstalled = (skubannaLoggedIn, skubanaAuthorized, quickbooksAuthorized) => (
      <div className='form-group mb-0 text-center'>
        {skubannaLoggedIn && quickbooksAuthorized && quickbooksAuthorized ? (
            <button
                onClick={Utils.redirectToAdmin}
                className='btn btn-primary'
                type='submit'>
              <i className='mdi mdi-home mr-1'/>
              Proceed to Admin
            </button>
        ) : (<br />)}
      </div>
  )

  userAuthenticated = (skubannaLoggedIn) => (
      <div className='form-group mb-0 text-center'>
        {!skubannaLoggedIn ? (
            <button
                onClick={Utils.redirectToSkubana}
                className='btn btn-primary'
                type='submit'>
              <i className='mdi mdi-home mr-1'/>
              Proceed to Admin
            </button>
        ) : (<br />)}
      </div>
  )

  determinePageToDisplay = () => {
    const { skubanaAuthorized, quickbooksAuthorized, quickbooksOauthUrl } = this.props.settings

    const skubannaLoggedIn = this.props.user.authenticated
    return (
      <div>
        <Documentation entryId='6bLLegcc7UNdimpQh3AzeP' />
        {this.userAuthenticated(skubannaLoggedIn)}
        {skubannaLoggedIn && this.quickbooksInstalled(skubannaLoggedIn, skubanaAuthorized)}
        {skubannaLoggedIn && skubanaAuthorized && this.quickbooksConnected(skubanaAuthorized, quickbooksAuthorized, quickbooksOauthUrl)}
        {this.quickbooksConnectedAndInstalled(skubannaLoggedIn, skubanaAuthorized, quickbooksAuthorized)}
      </div>
    )
  }

  render() {
    if (!this.props.user.authenticated) return <Login />

    return (
      <div className='container justify-content-center'>
        <div className='mt-3 col-xs-12'>
          <Card style={{ padding: 0 }}>
            <div className='card-header pt-3 pb-3 text-center bg-primary'>
              <div className='logo logo-lg text-center' dangerouslySetInnerHTML={{ __html: logo }} />
            </div>
            <div className='card-body'>
              { this.determinePageToDisplay() }
            </div>
          </Card>
        </div>
      </div>
    )
  }
}

export default connect(({ user, quickbooks, userSettings: settings }) => ({ user, quickbooks, settings }))(Account)
